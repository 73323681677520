<template>
  <div
    class="case-study container"
    :id="currentStudy.nickname"
    :key="currentStudy.nickname"
  >
    <div class="case-study__upper-content">
      <!--=====================
     CURRENT CASE
     ======================== -->

      <div class="case-study__current-case">
        <page-intro
          :title="currentStudy.client"
          :subtitle="currentStudy.project"
          :description="currentStudy.description"
          :fadeIn="fadeIns"
        />

        <div class="case-study__featured">
          <!-- ':key' attribute forces replacement (vs. reuse) when changing between studies so that
          videos dont reuse landscape/portrait css layout from the previous video -->
          <vimeo-embed
            :key="$route.path"
            v-if="currentStudy.featuredVideo"
            :videoId="currentStudy.featuredVideo"
            :fadeIn="fadeIns"
          />
          <img
            v-else-if="currentStudy.featuredImage"
            :src="getImgSrc(currentStudy.featuredImage)"
            alt=""
            class="img-fluid w-100"
          />
        </div>

        <case-media-grid-vertical
          v-if="currentStudy.nickname === 'el-jim-cup'"
          :media="currentStudy.media"
          :key="$route.path"
        />
        <case-media-grid
          v-else
          :media="currentStudy.media"
          :key="$route.path"
        />

        <credits :creditColumns="currentStudy.creditColumns" />
      </div>

      <!--=====================
     CASE NAV
     ======================== -->

      <div class="case-study__nav">
        <router-link
          class="case-study__nav__link mb-0"
          :to="'/study/' + prevStudy.nickname"
        >
          <h3 class="h3">Previous Work</h3>
        </router-link>
        <router-link
          class="case-study__nav__link d-none d-lg-block mb-0"
          to="/our-work"
        >
          <h3 class="h3 text-center">All Work</h3>
        </router-link>
        <span class="case-study__nav__link mb-0" @click="onNextStudyClick">
          <h3 class="h3 text-end">Next Project</h3>
        </span>
      </div>

      <hr class="full-width-xs" />
    </div>
    <!-- end upper content -->

    <!--=====================
     NEXT CASE
     ======================== -->

    <div class="case-study__next-case" @click="onNextStudyClick">
      <page-intro
        class=""
        :title="nextStudy.client"
        :subtitle="nextStudy.project"
        :description="nextStudy.description"
        :fadeIn="fadeIns"
      />

      <div class="case-study__featured">
        <vimeo-embed
          v-if="nextStudy.featuredVideo"
          :videoId="nextStudy.featuredVideo"
          :fadeIn="fadeIns"
        />
        <img
          v-else-if="nextStudy.featuredImage"
          :src="getImgSrc(nextStudy.featuredImage)"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { caseStudies as allCaseStudies } from "@/assets/caseStudies";
import PageIntro from "@/components/PageIntro.vue";
import VimeoEmbed from "@/components/VimeoEmbed.vue";
import Credits from "@/components/Credits.vue";
import CaseMediaGrid from "../components/CaseMediaGrid.vue";
import CaseMediaGridVertical from "../components/CaseMediaGridVertical.vue";

export default {
  name: "CaseStudy",
  components: {
    PageIntro,
    VimeoEmbed,
    CaseMediaGrid,
    CaseMediaGridVertical,
    Credits,
  },
  props: [],
  data() {
    return {
      currentStudy: null,
      nextStudy: null,
      prevStudy: null,
      fadeIns: true,
      currentDept: null,
      studiesInCurrentDept: [],
    };
  },
  created() {
    this.initialize();
    this.watchRouteForStudySwap();
  },
  methods: {
    initialize() {
      this.currentStudy = allCaseStudies.find(
        (study) => study.nickname === this.$route.params.nickname,
      );
      this.currentDept = this.$route.params.activeDept
        ? this.$route.params.activeDept
        : this.currentDept;
      this.studiesInCurrentDept = this.currentDept === "all"
          ? allCaseStudies
          : allCaseStudies.filter(
              (study) => study.category === this.currentDept,
            );
      this.setNextAndPrevStudies();
    },
    setNextAndPrevStudies() {
      const deptStudies = this.studiesInCurrentDept;
      const len = deptStudies.length;
      const index = deptStudies.indexOf(this.currentStudy);
      const indexOfNextStudy = index === len - 1 ? 0 : index + 1;
      const indexOfPrevStudy = index === 0 ? len - 1 : index - 1;
      this.nextStudy = deptStudies[indexOfNextStudy];
      this.prevStudy = deptStudies[indexOfPrevStudy];
    },
    getImgSrc(filename) {
      /* eslint-disable */
      return require("@/assets/images/case-study-images/" + filename);
      /* eslint-enable */
    },
    removeFadeIns() {
      this.fadeIns = false;
      document.querySelectorAll(".page-intro__column").forEach((column) => {
        column.classList.remove("aos-init", "aos-animate");
      });
      document
        .querySelectorAll(".case-study__featured .vimeo-embed")
        .forEach((video) => {
          video.classList.remove("aos-init", "aos-animate");
        });
    },
    watchRouteForStudySwap() {
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if (toParams.nickname) {
            this.initialize();
          }
        },
      );
    },
    onNextStudyClick() {
      const that = this;
      const current = document.querySelector(".case-study__upper-content");
      const navbar = document.querySelector(".navbar");
      const next = document.querySelector(".case-study__next-case");
      const navHeight = document.querySelector(".navbar").offsetHeight;
      const footer = document.querySelector(".footer");

      const first = () => {
        that.removeFadeIns();
        navbar.style.opacity = 0;
        current.style.opacity = 0;
        footer.style.transition = "opacity 0.6s ease-in-out";
        footer.style.opacity = 0;
        next.style.overflow = "visible";
      };
      const second = () => {
        current.style.height = `${current.offsetHeight}px`;
        next.style.height = `${next.offsetHeight}px`;
        current.style.transition = "height .8s ease-in-out";
        current.style.height = "0px";
        next.style.height = `calc(100vh - ${navHeight - 1}px)`;
        footer.style.display = "none";
        next.style.overflow = "hidden";
      };
      const third = () => {
        that.$router.push({
          name: "Study",
          params: { nickname: that.nextStudy.nickname },
        });
        navbar.style.opacity = 1;
        footer.style.opacity = 1;
        footer.style.display = "block";
      };

      first();
      setTimeout(second, 700);
      setTimeout(third, 2000);
    },
  },
};
</script>

<style lang="scss">
.case-study {
  &__upper-content {
    transition: opacity 0.6s ease-in-out, height 0.8s ease-in-out;
    overflow: hidden;
    opacity: 1;
  }

  &__featured {
    margin-bottom: 40px;
  }

  &__next-case {
    position: relative;
    height: 350px;
    overflow: hidden;
    cursor: pointer;
    transition: height 0.8s ease-in-out;

    &.expanded {
      height: 100vh;
    }

    @include bp(tablet) {
      height: 579px;

      &.expanded {
        height: 100vh;
      }
    }

    .case-study__featured {
      pointer-events: none;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 160px;

    @include bp(desk-l) {
      margin-top: 240px;
    }

    &__link {
      width: 50%;
      margin-bottom: 15px;
      cursor: pointer;

      @include bp(desk-l) {
        width: 33%;
      }

      &:hover {
        text-decoration: none;
      }

      @media (max-width: 767px) {
        .h3 {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
