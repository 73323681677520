<template>
  <div class="about">
    <page-intro
      title="Introduction"
      :description="introDescription"
      class="container"
    />

    <div class="about__video container-md">
      <vimeo-embed videoId="592879977" class="full-width-xs-sm" />
    </div>

    <div class="about__spotlight">
      <page-intro
        title="Culture"
        :description="cultureDescription"
        :darkTheme="true"
        class="container"
      />
    </div>

    <staff-grid class="container" />

    <div class="about__looking">
      <h3 class="h1">We are looking for team members</h3>
      <a class="button" href="mailto:info@the3productions.com?subject=Looking for team members!">Contact Us</a>
      <!-- <router-link to="/contact" class="button">Contact Us</router-link> -->
    </div>
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro.vue";
import StaffGrid from "@/components/StaffGrid.vue";
import VimeoEmbed from "@/components/VimeoEmbed.vue";

export default {
  name: "About",
  components: { PageIntro, StaffGrid, VimeoEmbed },
  props: [],
  emits: [],
  data() {
    return {
      introDescription:
        "There’s something about experiencing stories vicariously through movement. We could be transported, we could lose all sense of reality through moving pictures. We love the effect this has on us; we are moved by motion.",
      cultureDescription:
        "We don’t take ourselves too seriously, but we are serious about what we do. We are flexible about solutions and seek diversity within our team.",
    };
  },
  methods: {},
  metaInfo() {
    return {
      title: "About The 3 - A Content Creation Studio",
      description: "We are The 3. From mobile videos to TV commercials, our production team makes content for every screen. All within your budget.",
    };
  },
};
</script>

<style lang="scss">
.about {
  &__spotlight {
    background: {
      image: url("~@/assets/images/spotlight-with-smoke.jpg");
      position: top right;
      repeat: no-repeat;
      size: cover;
    }
    height: 539px;

    @include bp(tablet) {
      height: 671px;
    }
  }

  .staff {
    margin-top: -198px;

    @include bp(tablet) {
      margin-top: -369px;
    }
  }

  &__looking {
    background: {
      image: url("~@/assets/images/looking-lighter.jpg");
      repeat: no-repeat;
      size: cover;
      position-x: 47%;
    }
    height: 478px;
    padding: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include bp(tablet) {
      height: 400px;
    }

    .h1 {
      color: white;
      text-align: center;
      margin-bottom: 48px;
    }

    .button {
      border-color: white;
      color: white;
      background: transparent;
    }
  }
}
</style>
