<template>
  <div class="credits mt-0 mt-lg-5">
    <p class="credits__main-title">Credit:</p>
    <div class="credits__columns">
      <div class="credits__spacer-column d-md-none"></div>
      <div
        v-for="col in creditColumns"
        class="credits__column"
        :key="col.title"
      >
        <p class="credits__column__title">{{ col.title }}</p>
        <div class="credits__column__names">
          <p
            class="credits__column__name"
            v-for="name in col.names"
            :key="name"
          >
            {{ name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credits",
  props: {
    creditColumns: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.credits {
  overflow: visible;
}

.credits__main-title {
  text-align: center;
  color: $primaryFontColor;
}

.credits__columns {
  display: flex;
  justify-content: center;

  // overflow container scroll on mobile
  @media (max-width: 767px) {
    position: relative;
    width: 98vw;
    margin-left: -50vw;
    left: 50%;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* hide scrollbar IE and Edge */
    scrollbar-width: none; /* hide scrollbar Firefox */

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar Chrome, Safari and Opera */
    }
    justify-content: flex-start;
  }

  .credits__column {
    flex: 1 0 37vw;
    padding: 10px;

    @include bp(tablet-s) {
      flex: 1 0 30vw;
    }

    @include bp(tablet) {
      flex: 1 0 23vw;
    }

    &:last-of-type {
      @media (max-width: 767px) {
        margin-right: 20px;
      }
    }

    @include bp(tablet) {
      flex: 1 1 175px;
    }

    &__title {
      color: $primaryFontColor;
      line-height: 21px;
    }

    &__name {
      color: $secondaryFontColor;
    }
  }

  .credits__spacer-column {
    min-width: 17vw;
  }
}
</style>
