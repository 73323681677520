<template>
  <footer class="footer">
    <div class="container">
      <div class="row footer__main-content">
        <!-- ==================
        LOGO
        ======================= -->

        <div class="col col-lg-2 d-lg-block footer__logo">
          <img src="../assets/images/logo-the-3-white.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- ==================
      COPYRIGHT
      ======================= -->

    <div class="footer__copyright">
      © {{ new Date().getFullYear() }} The 3 Productions
    </div>

    <!-- ==================
      OVAREGROUP LINK
      ======================= -->

    <div class="footer__ovaregroup">
      <p>
        The 3 is part of
        <a href="http://ovaregroup.com/" target="_blank" rel="noreferrer">
          <span class="font-weight-bold">Ovare</span>Group
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  props: [],
  emits: [],
  methods: {},
};
</script>

<style lang="scss">
.footer {
  background: $primaryDarkBackgroundColor;
  color: $primaryLightFontColor;

  .h6 {
    color: $primaryLightFontColor;
    margin-bottom: 9px;
    text-align: center;

    @include bp(tablet) {
      text-align: left;
    }
  }
}

.footer__main-content {
  padding: 33px 0;
  flex-direction: column;

  @include bp(tablet) {
    flex-direction: row;
    justify-content: center;
  }

  @include bp(desk-l) {
    padding: 40px 0;
  }
}

.footer__logo {
  text-align: center;
}

.footer__nav {
  .nav-link {
    color: $primaryLightFontColor;

    &:hover {
      color: $primaryLightFontColor;
    }
  }
}

.footer__social {
  @media (max-width: 767px) {
    text-align: center;
    padding-top: 42px;
    padding-bottom: 42px;

    &__icons-container {
      width: 267px;
      display: flex;
      justify-content: space-between;
      margin: auto;
    }
  }

  .icon-wrapper {
    display: inline-block;
    margin: 0 px(24) 0 0;
    max-width: px(44);
    transition: transform 0.15s;

    &:last-of-type {
      margin-right: 0;
    }

    @include bp(desk-l) {
      margin-right: px(16);
    }

    @include bp(full) {
      margin-right: px(24);
    }

    @include hover() {
      transform: scale(1.15);
    }

    img {
      width: 100%;
      height: auto;

       @media (max-width: 767px) {
         width: 35px;
       }
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: $secondaryLightFontColor;
  padding: 16px 0;
}

.footer__ovaregroup {
  text-align: center;
  background-color: $secondaryDarkBackgroundColor;
  padding: 16px 0;

  @include bp(desk-l) {
    padding: 24px 0;
  }

  p {
    color: $primaryLightFontColor;
    margin-bottom: 0;
  }

  .font-weight-bold {
    font-weight: 700;
  }
}
</style>
