<template>
  <div class="cmg">
    <div class="cmg__grid-container">
      <div class="cmg__grid-item col1item1">
        <case-media-grid-item :item="media.col1item1" />
      </div>
      <div class="cmg__grid-item col1item2">
        <case-media-grid-item :item="media.col1item2" />
      </div>
      <div class="cmg__grid-item col2item1">
        <case-media-grid-item :item="media.col2item1" />
      </div>
      <div class="cmg__grid-item col2item2">
        <case-media-grid-item :item="media.col2item2" />
      </div>
      <div class="cmg__grid-item col3item1">
        <case-media-grid-item :item="media.col3item1" />
      </div>
      <div class="cmg__grid-item col3item2">
        <case-media-grid-item :item="media.col3item2" />
      </div>
    </div>
  </div>
</template>

<script>
import CaseMediaGridItem from "@/components/CaseMediaGridItem.vue";

export default {
  name: "CaseMediaGridVertical",
  components: { CaseMediaGridItem },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/* ================
      MOBILE
=================== */

.cmg {
  &__grid-container {
    display: grid;
    grid-gap: 4px;
    background-color: white;
    padding-bottom: 15px;
  }

  &__grid-item {
    overflow: hidden;
  }
}

/* ================
    TABLET AND UP
=================== */

@include bp(tablet) {
  .cmg {
    &__grid-container {
      grid-template-areas:
        "a b c"
        "d e c"
        "d e f";
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: 232px 172px 232px;

      @include bp(desk-l) {
        grid-template-rows: 312px 230px 312px;
      }

      @include bp(full) {
        grid-template-rows: 372px 275px 372px;
      }

      @include bp(xl) {
        grid-template-rows: 432px 319px 432px;
        grid-gap: 5px;
      }
    }

    &__grid-item {
      &.col1item1 {
        grid-area: a;
      }
      &.col1item2 {
        grid-area: d;
      }
      &.col2item1 {
        grid-area: b;
      }
      &.col2item2 {
        grid-area: e;
      }
      &.col3item1 {
        grid-area: c;
      }
      &.col3item2 {
        grid-area: f;
      }
    }
  }
}
</style>
