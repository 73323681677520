export const foo = 'bar';

export const caseStudies = [

  /* ============================
  PRODUCTION
  ============================== */

  {
    client: 'GEA',
    project: 'Small Appliance Holiday',
    nickname: 'ge-holiday',
    category: 'production',
    description: "GE Appliances wanted to showcase its new line of small appliances and how each can be used to create a holiday meal. The 3 partnered with Scoppechio to create a series of social videos that highlighted each featured appliance. In the end, we created four social videos that helped drive engagement and sale of GEA's new small appliances.",
    featured: false,
    featuredImage: 'ge-holiday-featured.png',
    featuredVideo: '566205721',
    metaInfo: {
      title: "GEA - Small Appliance Holiday",
      description: "We created four social videos that helped drive engagement and sale of GEA's new small appliances.",
    },
    media: {
      row1col1: { image: 'ge-holiday-oven-and-food-display.jpg' },
      row1col2: { video: '582535694' },
      row2col1: { video: '566206060' },
      row2col2: { image: 'ge-holiday-oven-closed-turkey.jpg' },
      row3col1: { video: '566205264' },
      row3col2: { video: '566204176' },
      row4col1: { video: '566206307' },
      row4col2: { image: 'ge-holiday-mixers-various.jpg' },
      row4col3: { video: '566206422' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Pete Maniscalco'],
      },
      {
        title: 'Producer',
        names: ['Marisa Gartland'],
      },
      {
        title: 'Director of Photography',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Food Stylist',
        names: ['Sara Rounsavall'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Title Card Animation',
        names: ['Jacob Campbell'],
      },
    ],
  },
  {
    client: 'GEA',
    project: 'Smart Window Air Conditioning Unit',
    nickname: 'ge-smart',
    category: 'production',
    description: 'GE Appliances is now offering a smart line of window A/C units. The company wanted to showcase the features and benefits of this innovative product line. With the help of Scoppechio, we created 8 feature & benefits videos on a one-day shoot at several locations. These products represented entry-level to mid-level models of the GE Profile\u2122 brand.',
    featured: false,
    featuredImage: 'ge-smart-featured.png',

    featuredVideo: '566182989',
    media: {
      row1col1: { image: 'ge-smart-1.jpg' },
      row1col2: { video: '566182898' },
      row2col1: { video: '566182646' },
      row2col2: { image: 'ge-smart-4.jpg' },
      row3col1: { image: 'ge-smart-5.jpg' },
      row3col2: { video: '566182738' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Pete Maniscalco'],
      },
      {
        title: 'Director Producer',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Director of Photography',
        names: ['Nate Spicer'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Animation',
        names: ['Jacob Campbell', 'Remington Markham'],
      },

    ],
  },
  {
    client: "Fazoli's",
    project: 'Food Photography',
    nickname: 'fazolis-food',
    category: 'production',
    description: "Fazoli's regularly introduces NEW menu items and LIMITED TIME ONLY food options. We partnered with Ad Cuc\xECna to create mouthwatering food photography that helps to generate iconic imagery for Fazoli's",
    featured: false,
    featuredImage: 'fazolis-food-featured2.jpg',
    featuredVideo: null,
    media: {
      row1col1: { image: 'fazolis-food-1.jpg' },
      row1col2: { image: 'fazolis-food-2.jpg' },
      row2col1: { video: '566182209' },
      row2col2: { image: 'fazolis-food-4.jpg' },
      row3col1: { image: 'fazolis-food-5.jpg' },
      row3col2: { image: 'fazolis-food-6.jpg' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Louis Fowler', 'Christine Jones'],
      },
      {
        title: 'Producer',
        names: ['Marisa Gartland'],
      },
      {
        title: 'Director of Photography',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Food Stylist',
        names: ['Sara Rounsavall'],
      },
      {
        title: 'Image Retouching',
        names: ['Tim Paschal'],
      },

    ],
  },
  {
    client: 'LG&E and KU',
    project: 'LG&E and KU Manifesto',
    nickname: 'lge-manifesto',
    category: 'production',
    description: "The 3 was tasked with helping to create a new anthem video for Louisville Gas & Electric and Kentucky Utilities companies. The goal was to create positive sentiment toward these utilities by showcasing the work they are doing in their communities and spotlighting the many ways these companies are demonstrating that they care about their customers. Scoppechio used 'This Little Light of Mine' as a theme song for this piece and The 3 facilitated music production. Overall positive sentiment toward LG&E and KU rose and the video received great feedback within the communities in which it aired.",
    featured: false,
    featuredImage: 'lge-manifesto-featured.jpg',
    featuredVideo: '566186418',
    media: {
      row1col1: { image: 'lge-manifesto-1.jpg' },
      row1col2: { video: '566189525' },
      row2col1: { video: '566189289' },
      row2col2: { image: 'lge-manifesto-4.jpg' },
      row3col1: { image: 'lge-manifesto-5.jpg' },
      row3col2: { video: '566189362' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Producer',
        names: ['Barry Zeidman', 'Thom Little'],
      },
      {
        title: 'Director/DP',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Animation',
        names: ['Jacob Campbell'],
      },
      {
        title: 'Music Production',
        names: ['Haus Music + Sound'],
      },

    ],
  },
  {
    client: 'el Jimador',
    project: 'Cocktail',
    nickname: 'el-jim-cocktail',
    category: 'production',
    description: 'el Jimador Tequila needed to refresh its cocktails and needed to promote them on more screens than ever before. The 3 partnered with Scoppechio to create over 200 photos, GIFs, videos, cinemagraphs, stop motion clips, and more to execute on every screen size.',
    featured: false,
    featuredImage: 'el-jim-cocktail-featured.png',
    featuredVideo: '566165245',
    media: {
      row1col1: { image: 'el-jim-cocktail-1.jpg' },
      row1col2: { image: 'el-jim-cocktail-2.png' },
      row2col1: { video: '566164918' },
      row2col2: { image: 'el-jim-cocktail-4.jpg' },
      row3col1: { image: 'el-jim-cocktail-5.png' },
      row3col2: { video: '566165105' },
      row4col1: { video: '566165189' },
      row4col2: { image: 'el-jim-cocktail-8.jpg' },
      row4col3: { image: 'el-jim-cocktail-9.jpg' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Lanny Fuller', 'Hillary McDaniel', 'Aaron Carter'],
      },
      {
        title: 'Producer',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Photographer',
        names: ['Dean Lavenson'],
      },
      {
        title: 'Director of Photography',
        names: ['Joe Laughrey'],
      },
      {
        title: 'Cocktail Stylist',
        names: ['Sara Rounsavall'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
    ],
  },
  {
    client: 'LG&E and KU',
    project: 'The Pet Station Country Club',
    nickname: 'lge-pet',
    category: 'production',
    description: 'LG&E offers a commercial rebate program and needed help getting businesses to enroll to take advantage of all the money- and energy-saving benefits the program offers. The 3 partnered with Scoppechio to create a testimonial that showcased all the benefits of working with LG&E, with a real-life example of how one local business was able to maximize its energy savings as a commercial rebate program participant.',
    featured: false,
    featuredImage: 'lge-pet-featured.png',
    featuredVideo: '566189570',
    media: {
      row1col1: { image: 'lge-pet-1.jpg' },
      row1col2: { video: '566190580' },
      row2col1: { video: '566190345' },
      row2col2: { image: 'lge-pet-4.jpg' },
      row3col1: { image: 'lge-pet-5.jpg' },
      row3col2: { video: '566190437' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Producer',
        names: ['Thom Little'],
      },
      {
        title: 'Director/DP/ Still Photographer',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
    ],
  },
  {
    client: 'KFC',
    project: 'Beyond KFC',
    nickname: 'kfc-beyond',
    category: 'production',
    description: 'KFC wanted to test a new menu offering: a meatless Beyond Chicken Nuggets product. We partnered with Ad Cuc\xECna to shoot photos for a market test in various parts of the States. This is also the first time that KFC has used a vegetarian recipe for its Chicken Nuggets.',
    featured: false,
    featuredVideo: null,
    featuredImage: 'kfc-beyond-featured.png',
    media: {
      row1col1: { image: 'kfc-beyond-1.jpg' },
      row1col2: { video: '566185478' },
      row2col1: { video: '566183630' },
      row2col2: { image: 'kfc-beyond-4.jpg' },
      row3col1: { image: 'kfc-beyond-5.jpg' },
      row3col2: { video: '566185431' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Pete Maniscalco'],
      },
      {
        title: 'Producer',
        names: ['Marisa Gartland'],
      },
      {
        title: 'Director of Photography',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Food Stylist',
        names: ['Sara Rounsavall'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Title Card Animation',
        names: ['Jacob Campbell'],
      },

    ],
  },

  /* ============================
  INTERACTIVE
  ============================== */

  {
    client: 'el Jimador',
    project: 'Cup Swap',
    nickname: 'el-jim-cup',
    description: 'el Jimador Tequila wanted to capture the feeling of an impossible lime trick shot with soccer star Jonathan Dos Santos. With help from The 3’s interactive team, we made this impossible kick possible for a big social media splash.',
    category: 'interactive',
    featured: false,
    featuredVideo: '588974313',
    featuredImage: 'el-jim-cup-featured.png',
    media: {
      col1item1: { image: 'el-jim-cup-lime-kick.jpg' },
      col1item2: { video: '566155351' },
      col2item1: { image: 'el-jim-cup-cutting-lime.jpg' },
      col2item2: { image: 'el-jim-cup-high-five.jpg' },
      col3item1: { video: '566155609' },
      col3item2: { image: 'el-jim-cup-lemonade-splash.jpg' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Pete Maniscalco'],
      },
      {
        title: 'Producer',
        names: ['Marisa Gartland'],
      },
      {
        title: 'Director of Photography',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Food Stylist',
        names: ['Sara Rounsavall'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Title Card Animation',
        names: ['Jacob Campbell'],
      },

    ],
  },
  {
    client: 'el Jimador',
    project: 'Rooftop',
    nickname: 'el-jim-rooftop',
    description: 'el Jimador Tequila was interested in creating a social post that at first seemed like a group of friends at the beach, but revealed that they were celebrating on a rooftop apartment complex. Instead of trying to explore multiple locations, The 3 interactive team created their own rooftop paradise.',
    category: 'interactive',
    featured: false,
    featuredVideo: '566158081',
    featuredImage: 'el-jim-rooftop-featured.jpg',
    media: {
      row1col1: { image: 'el-jim-rooftop-cityscape.jpg' },
      row1col2: { image: 'el-jim-rooftop-chatting.jpg' },
      row2col1: { image: 'el-jim-rooftop-bottle.jpg' },
      row2col2: { video: '566158412' },
      row3col1: { image: 'el-jim-rooftop-chatting-2.jpg' },
      row3col2: { video: '566158541' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Lanny Fuller', 'Hillary McDaniel', 'Aaron Carter'],
      },
      {
        title: 'Shoot Production Company',
        names: ['Kiddo'],
      },
      {
        title: 'Director',
        names: ['Mike Seehagel'],
      },
      {
        title: 'Producers',
        names: ['Thom Little', 'Genevieve Strachan'],
      },
      {
        title: '3D Artist and VFX Compositing',
        names: ['Katie Bially'],
      },
    ],
  },
  {
    client: 'LG&E and KU',
    project: 'Marketplace',
    nickname: 'lge-marketplace',
    description: 'LG&E and KU wanted to showcase their new marketplace website for finding great energy-efficient deals on appliances. We partnered with Scoppechio to create broadcast 15- and 30-second spots that showed the vast array of options. This project was filmed in our studio and our VFX team created the appliances and environments.',
    category: 'interactive',
    featured: false,
    featuredVideo: '566163874',
    featuredImage: 'lge-marketplace-featured.jpg',
    media: {
      row1col1: { image: 'lge-marketplace-woman-and-fridge.jpg' },
      row1col2: { video: '566163816' },
      row2col1: { image: 'lge-marketplace-cartoon-man.jpg' },
      row2col2: { video: '566163294' },
      row3col1: { video: '566163751' },
      row3col2: { image: 'lge-marketplace-fridge-farm.jpg' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Director/DP',
        names: ['Ravi Bhatia'],
      },
      {
        title: 'Producer',
        names: ['Jill Becker'],
      },
      {
        title: '3D Artist and VFX Compositing',
        names: ['Brandon Clements'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
    ],
  },

  /* ============================
  ANIMATION
  ============================== */

  {
    client: 'Baptist Health',
    projectAbbreviation: 'Lexington',
    project: "Lexington Women's Healthcare Campaign",
    nickname: 'baptist-lexington',
    description: 'Women tend to balance a lot. While they are focused on their careers, their family, and their hobbies, Baptist Health Lexington is focused on their healthcare. The 3 partnered with &well to show how Baptist Health Lexington is the best place for women to get check-ups, screenings, and care that is personalized to them.',
    featured: true,
    category: 'animation',
    featuredVideo: '566129225',
    featuredImage: 'baptist-lexington-featured.jpg',
    media: {
      row1col1: { image: 'baptist-lex-homework-help.jpg' },
      row1col2: { image: 'baptist-lex-smiling-woman.jpg' },
      row2col1: { video: '566129757' },
      row2col2: { image: 'baptist-lex-2-sketches.jpg' },
      // these images don't fit the grid (they span all 11 columns in the design)
      // row3col1: { image: 'baptist-lex-bag-handoff-panorama.jpg' },
      // row3col2: { image: 'baptist-lex-business-mtg-panorama.jpg' },
      row4col1: { video: '566129705' },
      row4col2: { video: '566129790' },
      row4col3: { video: '566129729' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Art Direction',
        names: ['Jacob A. Campbell'],
      },
      {
        title: 'Producer',
        names: ['Jill Becker'],
      },
      {
        title: 'Illustration',
        names: ['Jacob A. Campbell', 'Remington Markham'],
      },
      {
        title: 'Animation',
        names: ['Jacob A. Campbell', 'Remington Markham', 'Brandon Clements'],
      },
    ],
  },
  {
    client: 'Baptist Health',
    project: 'Urgent Care',
    nickname: 'baptist-urgent',
    description: "Tasked with updating the seasonal creative to incorporate the overarching message of convenience, we partnered with &well to create a campaign that was engaging and personal. Our illustration style was converted to 3D, which allowed us to further push the creative stylistically. The design and animation style conveyed the convenience message clearly. Baptist Health's Urgent Care Clinics take pride in helping patients, 'Get in, get out and get on with their life.'",
    featured: true,
    category: 'animation',
    featuredVideo: '566145028',
    featuredImage: 'baptist-urgent-featured.png',
    media: {
      row1col1: { image: 'baptist-urgent-green-car-family.jpg' },
      row1col2: { image: 'baptist-urgent-aqua-shirt-man.jpg' },
      row2col1: { video: '566146462' },
      row2col2: { image: 'baptist-urgent-pink-clinic.jpg' },
      row3col1: { image: 'baptist-urgent-family-lineup.jpg' },
      row3col2: { video: '566146353' },
      row4col1: { image: 'baptist-urgent-road-billboard.jpg' },
      row4col2: { image: 'baptist-urgent-strep-throat.jpg' },
      row4col3: { image: 'baptist-urgent-a-plus.jpg' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Jacob A. Campbell', 'Trace Conn', 'Jorge E. Torneria'],
      },
      {
        title: 'Story',
        names: ['Mary Ann Vollmer'],
      },
      {
        title: 'Illustration & Design',
        names: ['Jacob A. Campbell', 'Remington Markham'],
      },
      {
        title: '3D Animation',
        names: ['Remington Markham', 'Brandon Clements'],
      },
      {
        title: 'Animation',
        names: ['Jacob A. Campbell'],
      },
    ],
  },
  {
    client: 'Baptist Health',
    project: 'Heart Month',
    projectAbbreviation: 'Heart Month',
    nickname: 'baptist-heart',
    featured: true,
    category: 'animation',
    description: 'Are you at risk for heart disease? The 3 partnered with &well to help Baptist Health create content that informed its customers about the ease of taking an online Heart Risk Assessment. The animations promote exercise and eating right, while showing how Baptist Health makes it easy to find your heart age.',
    featuredVideo: '566136961',
    featuredImage: 'baptist-heart-featured.png',
    media: {
      row1col1: { image: 'baptist-heart-row1-col1.jpg' },
      row1col2: { video: '566137765' },
      row2col1: { video: '566137694' },
      row2col2: { image: 'baptist-heart-row2-col2.jpg' },
      row3col1: { image: 'baptist-heart-row3-col1.jpg' },
      row3col2: { video: '566137721' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Art Direction',
        names: ['Jacob A. Campbell'],
      },
      {
        title: 'Producer',
        names: ['Thom Little'],
      },
      {
        title: 'Illustration',
        names: ['Jacob A. Campbell', 'Remington Markham'],
      },
      {
        title: 'Animation',
        names: ['Jacob A. Campbell', 'Remington Markham'],
      },
    ],
  },
  {
    client: 'Mortenson Family Dental',
    project: 'Smiles 360',
    nickname: 'mort-360',
    description: 'Paying for dental work can be confusing and intimidating. Mortenson’s Smiles 360 Dental Savings Club is an alternative to either navigating the insurance maze or paying full price. The 3 partnered with &Well to illustrate this amazing service that makes paying for dental work painless!',
    featured: true,
    category: 'animation',
    featuredVideo: '566149557',
    featuredImage: 'mort-360-featured.png',
    media: {
      row1col1: { image: 'mort-360-row1-col1.jpg' },
      row1col2: { video: '566149886' },
      row2col1: { video: '566149516' },
      row2col2: { image: 'mort-360-row2-col2.jpg' },
      row3col1: { image: 'mort-360-row3-col1.jpg' },
      row3col2: { video: '566149466' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Mark Rosenthal'],
      },
      {
        title: 'Art Direction',
        names: ['Jacob A. Campbell'],
      },
      {
        title: 'Producer',
        names: ['Ravi Bhatia', 'Thom Little'],
      },
      {
        title: 'Illustration',
        names: ['Jacob A. Campbell', 'Remington Markham'],
      },
      {
        title: 'Animation',
        names: ['Jacob A. Campbell', 'Remington Markham', 'Brandon Clements'],
      },

    ],
  },
  {
    client: 'Laredo Medical Center',
    project: 'Laredo Medical Center',
    nickname: 'laredo-work',
    description: 'Laredo Medical Center wanted to announce the opening of its new Emergency Medical Center. The 3 partnered with &well to create an animation that highlighted the convenience of its new location. We created English & Spanish versions of a 30-second commercial that helped create awareness and promote its grand opening.',
    featured: true,
    category: 'animation',
    featuredVideo: '566147513',
    featuredImage: 'laredo-work-featured.png',
    media: {
      row1col1: { image: 'laredo-work-row1-col1.jpg' },
      row1col2: { video: '566148274' },
      row2col1: { image: 'laredo-work-row2-col1.jpg' },
      row2col2: { video: '566147487' },
      row3col1: { video: '566147441' },
      row3col2: { video: '566148140' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Trace Conn', 'Michael Lancaster', 'Mary Ann Vollmer'],
      },
      {
        title: 'Producer',
        names: ['Thom Little'],
      },
      {
        title: 'Illustration',
        names: ['Jasmine Kenya', 'Remington Markham', 'Jacob A. Campbell'],
      },
      {
        title: 'Animation',
        names: ['Jasmine Kenya', 'Jacob A. Campbell'],
      },
    ],
  },
  {
    client: 'Purina',
    project: 'PetQCheck',
    nickname: 'purina-check',
    description: 'PetQCheck was looking for engaging video content to communicate the effectiveness of its science-backed research methods to assess the “gut health” of your pet. The 3 partnered with PetQCheck to showcase the easy-to-use Gut Health Insights Test and the benefits of their product. We created two longform animations with multiple cutdowns for use across a variety of platforms.',
    featured: true,
    category: 'animation',
    featuredVideo: '566151804',
    featuredImage: 'purina-check-featured.png',
    media: {
      row1col1: { image: 'purina-check-row1-col1.jpg' },
      row1col2: { video: '566153913' },
      row2col1: { video: '566153893' },
      row2col2: { image: 'purina-check-row2-col2.jpg' },
      row3col1: { image: 'purina-check-row3-col1.jpg' },
      row3col2: { video: '566153675' },
      row4col1: { video: '566153947' },
      row4col2: { image: 'purina-check-row4-col2.jpg' },
      row4col3: { video: '566153930' },
    },
    creditColumns: [
      {
        title: 'Creative Direction',
        names: ['Jacob A. Campbell', 'Steve Leader'],
      },
      {
        title: 'Script',
        names: ['Stephen Childress'],
      },
      {
        title: 'Producer',
        names: ['Thom Little'],
      },
      {
        title: 'Illustration',
        names: ['Kirksey Wells', 'Remington Markham', 'Jacob A. Campbell'],
      },
      {
        title: 'Editor',
        names: ['Katie Bially'],
      },
      {
        title: 'Animation',
        names: ['Jacob A. Campbell', 'Russ Caldwell', 'Jasmine Kenya', 'Brandon Clements', 'Katie Bially', 'Brandon Radford', 'Ryan Blazo'],
      },
    ],
  },
];
