<template>
  <div class="animation container">
    <page-intro title="Animation" :description="description" />

    <vimeo-embed videoId="567611050">
      <video-caption
        primaryTitle="Baptist Health"
        secondaryTitle="Stroke Awareness"
      />
    </vimeo-embed>
    <vimeo-embed videoId="566145028">
      <video-caption
        primaryTitle="Baptist Health"
        secondaryTitle="Urgent Care"
      />
    </vimeo-embed>
    <vimeo-embed videoId="566151804">
      <video-caption primaryTitle="Purina" secondaryTitle="PetQCheck" />
    </vimeo-embed>

    <departments currentDept="animation" />
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro.vue";
import VimeoEmbed from "@/components/VimeoEmbed.vue";
import Departments from "../components/Departments.vue";
import VideoCaption from "../components/VideoCaption.vue";

export default {
  name: "Animation",
  components: {
    PageIntro,
    VimeoEmbed,
    VideoCaption,
    Departments,
  },
  props: [],
  emits: [],
  data() {
    return {
      description:
        "Animation is a medium that we were raised to love. Animation allows our clients to go to worlds that they can’t normally go to if constrained by reality. There is an artistic aspect of animation that allows us to create emotional responses through thoughtful design.",
    };
  },
  methods: {},
  metaInfo() {
    return {
      title: "Animation at The 3",
      description: "Animation is a medium that we were raised to love.",
    };
  },
};
</script>

<style lang="scss">
</style>
