<template >
  <nav class="navbar navbar-light navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/" @click="collapseNavbar">
        <img
          src="../assets/images/logo-the-3-black.svg"
          alt="The 3 Logo in black outline"
        />
        <span class="visuallyhidden">Go back to the homepage</span>
      </router-link>

      <!-- USE MAILTO LINK FOR NOW -->
      <div class="nav-item d-lg-block">
        <a
          class="nav-link nav-link__custom contact-link__box"
          @click="collapseNavbar"
          href="mailto:lisa.berg@the3productions.com?subject=Agency Contact: The 3 Productions"
          >Contact</a
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    collapseNavbar() {
      document.querySelector(".navbar-collapse").classList.remove("show");
      document.querySelector(".navbar-toggler").classList.add("collapsed");
    },
  },
};
</script>

<style lang="scss">
.navbar.navbar-light {
  border-bottom: 1px solid $lightBorder;
  opacity: 1;
  transition: opacity 1s ease;

  .navbar__contact-info__title {
    padding-top: 31px;
  }

  .navbar-brand {
    padding: 10px 25px;

    @include bp(tablet) {
      padding: 12px 25px;
    }

    img {
      width: 39px;

      @include bp(tablet) {
        width: 56px;
      }
    }
  }
  .navbar-toggler {
    border: none;
    box-shadow: none;

    &.collapsed {
      .navbar-toggler__close {
        display: none;
      }
    }

    &:not(.collapsed) {
      .navbar-toggler__burger {
        display: none;
      }
    }
  }

  .navbar-collapse {
    hr {
      color: $lightBorder;
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .contact-link {
    &__box {
      border: 1px solid $primaryFontColor;
      padding: 2px 12px 5px 12px !important;
      margin: 0 25px 0 0;
      transition: all 0.2s ease-in-out;
      color: $primaryFontColor;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.03);
        background: $primaryDarkBackgroundColor;
        color: $primaryLightFontColor !important;
        text-decoration: none;
      }
    }
  }
}
</style>
