<template>
  <div :class="['video-caption', { 'always-centered': alwaysCentered }]">
    <div v-if="primaryTitle" class="h2 primary-title">{{ primaryTitle }}</div>
    <div v-if="secondaryTitle" class="h2 secondary-title">
      {{ secondaryTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoCaption",
  props: {
    primaryTitle: {
      type: String,
      required: false,
    },
    secondaryTitle: {
      type: String,
      required: false,
    },
    alwaysCentered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.video-caption {
  margin-top: 36px;

  .primary-title,
  .secondary-title {
    text-align: center;
    line-height: 40px;
    font-weight: 500;
  }

  .secondary-title {
    color: $secondaryFontColor;
  }

  @include bp(desk-l) {
    .primary-title,
    .secondary-title {
      text-align: left;
    }

    // mimic empty column to left of element
    &:not(.always-centered) {
      margin-left: 8.3%;
    }

    &.always-centered {
      .primary-title,
      .secondary-title {
        text-align: center;
      }
    }
  }
}
</style>
