<template>
  <div class="vimeo-embed" :data-aos="fadeIn ? 'fade-up' : null">
    <vimeo-player
      ref="player"
      :videoId="videoId"
      class="embed-container"
      :options="getOptions()"
      @fullscreenchange="onFullScreenChange()"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VimeoEmbed",
  components: {},
  props: {
    videoId: {
      type: [Number, String],
      required: true,
    },
    autoplay: {
      type: Boolean,
    },
    fadeIn: {
      type: Boolean,
      default: true,
    },
  },
  emits: [],
  data() {
    return {
      isFullScreen: false,
    };
  },
  methods: {
    getOptions() {
      if (this.autoplay) {
        return {
          background: true,
          responsive: true,
          loop: true,
        };
      }
      return {
        responsive: true,
      };
    },
    onFullScreenChange() {
      if (this.autoplay) {
        const { player } = this.$refs;
        this.isFullScreen = !this.isFullScreen;
        this.isFullScreen ? player.unmute() : player.mute();
      }
    },
  },
};
</script>

<style lang="scss">
$maxVideoHeight: 770px;

.vimeo-embed {
  margin-bottom: 40px;

  @include bp(tablet) {
    margin-bottom: 75px;
  }

  .animation &,
  .production &,
  .interactive & {
    margin-bottom: 90px;

    @include bp(desk-l) {
      margin-bottom: 140px;
    }
  }

  @media (max-width: 540px) {
    margin-left: -12px;
    margin-right: -12px;
  }

  .embed-container {
    width: 100%;
    background: #b4b4b34f;
    background: $mediaBackgroundColor;
    max-height: $maxVideoHeight;

    iframe {
      max-height: $maxVideoHeight;
    }
  }
}
</style>
