<template>
  <div class="not-found container">
    <div class="not-found__content">
      <h1 class="h1">Page Not Found</h1>
      <p>No page found at this address.</p>
      <router-link class="button mt-2" to="/">Back to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss">

.not-found {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found__content {
  text-align: center;
  margin-bottom: 110px;

  .button {
    width: 100%;
  }
}
</style>
