<template>
  <div class="interactive container">
    <page-intro title="Interactive" :description="description" />

    <vimeo-embed videoId="588974313">
      <video-caption primaryTitle="el Jimador" secondaryTitle="Cup Swap" />
    </vimeo-embed>
    <vimeo-embed videoId="567613962">
      <video-caption primaryTitle="GEA" secondaryTitle="Washer" />
    </vimeo-embed>
    <vimeo-embed videoId="567614417">
      <video-caption primaryTitle="GEA" secondaryTitle="Oven Test" />
    </vimeo-embed>

    <departments currentDept="interactive" />
  </div>
</template>

<script>
import VimeoEmbed from "@/components/VimeoEmbed.vue";
import PageIntro from "@/components/PageIntro.vue";
import Departments from "../components/Departments.vue";
import VideoCaption from "../components/VideoCaption.vue";

export default {
  name: "Interactive",
  components: {
    VimeoEmbed,
    VideoCaption,
    Departments,
    PageIntro,
  },
  props: [],
  emits: [],
  data() {
    return {
      description:
        "The 3 is dedicated to providing the highest quality CG imagery and interactive applications to our clients.  We don’t create virtual worlds to check boxes, but to enhance the story and create a more immersive experience for the viewer. We believe in the flexibility of 3D products and all the usage possibilities they create.",
    };
  },
  metaInfo() {
    return {
      title: "Interactive at The 3",
      description:
        "The 3 is dedicated to providing the highest quality CG imagery and interactive applications to our clients.",
    };
  },
};
</script>

<style lang="scss">
</style>
