<template>
  <metainfo></metainfo>

  <a href="" tabindex="-1" ref="topOfPage"></a>
  <a href="#main" ref="skipLink" class="skip-to-content-link button"
    >Skip to content</a
  >

  <Navbar />
  <div id="main"></div>

  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transitionName || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>

  <site-footer />
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";
import AOS from "aos";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: { Navbar, SiteFooter },
  metaInfo() {
    return {
      title: "We Are The 3 - A Content Creation Studio",
    };
  },
  created() {
    AOS.init({
      duration: 600, // values from 0 to 3000, with step 50ms
      once: false, // whether animation should happen only once - while scrolling down
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  },
  computed: {
    isNotCaseStudy() {
      return this.$route.name !== "Study";
    },
  },
  watch: {
    $route() {
      this.$refs.topOfPage.focus();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
