<template>
  <img
    v-if="item.image"
    :src="getImgSrc(item.image)"
    alt=""
    class="cmg__image"
  />
  <template v-else-if="item.video">
    <vimeo-embed :videoId="item.video" :autoplay="true" class="cmg__video" />
  </template>
</template>

<script>
import VimeoEmbed from "./VimeoEmbed.vue";

export default {
  name: "CaseMediaGridItem",
  components: {
    VimeoEmbed,
  },
  props: ["item"],
  methods: {
    getImgSrc(filename) {
      /* eslint-disable */
      return require("@/assets/images/case-study-images/" + filename);
      /* eslint-enable */
    },
  },
};
</script>

<style lang="scss">
.cmg__video {
  @include scale-on-hover();
}

.cmg__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include scale-on-hover();
}

.cmg__grid-item .vimeo-embed .embed-container {
  margin-bottom: 0;
}
</style>
