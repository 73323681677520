<template>
  <div class="work container">
    <div
      class="d-flex justify-content-center align-items-center mt-4 mb-3 my-md-5"
    >
      <h1 class="h1" data-aos="fade-up">Our Work</h1>
    </div>

    <nav
      class="nav d-flex align-items-center justify-content-center my-4 my-md-3"
    >
      <a
        :class="['nav-link', { active: activeDept === 'all' }]"
        href="#"
        @click="onAllTabClick"
        data-aos="fade-left"
        data-aos-duration="200"
        data-aos-delay="300"
        >All</a
      >
      <a
        :class="['nav-link', { active: activeDept === 'animation' }]"
        href="#"
        @click="onAnimTabClick"
        data-aos="fade-left"
        data-aos-duration="200"
        data-aos-delay="200"
        >Animation</a
      >
      <hr class="work__nav-line-break d-md-none" />
      <a
        :class="['nav-link', { active: activeDept === 'interactive' }]"
        href="#"
        @click="onIntTabClick"
        data-aos="fade-left"
        data-aos-duration="300"
        >Interactive</a
      >
      <a
        :class="['nav-link', { active: activeDept === 'production' }]"
        href="#"
        @click="onProdTabClick"
        data-aos="fade-left"
        data-aos-duration="200"
        data-aos-delay="100"
        >Production</a
      >
    </nav>

    <div class="work__cases">
      <div
        v-for="caseStudy in visibleCases"
        :class="['work__case', caseStudy.nickname]"
        :key="caseStudy.nickname"
        data-aos="fade-up"
        @click="onStudyClick(caseStudy.nickname)"
      >
        <span>
          <img :src="getImgSrc(caseStudy.featuredImage)" alt="" />
          <div class="work__case__copy">
            <div class="h3">{{ caseStudy.client }}</div>
            <p>
              {{
                caseStudy.projectAbbreviation
                  ? caseStudy.projectAbbreviation
                  : caseStudy.project
              }}
            </p>
          </div>
        </span>
      </div>
    </div>

    <div
      v-if="showViewMoreBtn"
      class="work__view-more d-flex justify-content-center align-items-center"
    >
      <button class="h3 bg-white" @click="onViewMoreClick">View More</button>
    </div>
  </div>
</template>

<script>
import { caseStudies } from "@/assets/caseStudies";

export default {
  name: "Work",
  data() {
    return {
      allCases: caseStudies,
      visibleCases: [],
      otherCases: [],
      showViewMoreBtn: false,
      animationCases: [],
      productionCases: [],
      interactiveCases: [],
      activeDept: "all",
    };
  },
  created() {
    this.featuredCases = this.allCases.filter((c) => c.featured === true);
    this.otherCases = this.allCases.filter((c) => c.featured !== true);
    this.animCases = this.allCases.filter((c) => c.category === "animation");
    this.prodCases = this.allCases.filter((c) => c.category === "production");
    this.intCases = this.allCases.filter((c) => c.category === "interactive");
    this.activeDept = this.$route.params.dept ? this.$route.params.dept : "all";
    this.showInitialCases();
  },
  methods: {
    onStudyClick(nickname) {
      this.$router.push({
        name: "Study",
        params: {
          activeDept: this.activeDept,
          nickname,
        },
      });
    },
    getImgSrc(filename) {
      /* eslint-disable */
      return require("@/assets/images/case-study-images/" + filename);
      /* eslint-enable */
    },
    showInitialCases() {
      if (this.activeDept === "animation") {
        this.fadeCasesIntoView([], this.animCases);
      } else if (this.activeDept === "interactive") {
        this.fadeCasesIntoView([], this.intCases);
      } else if (this.activeDept === "production") {
        this.fadeCasesIntoView([], this.prodCases);
      } else {
        this.fadeCasesIntoView([], this.featuredCases);
      }
    },
    onViewMoreClick() {
      this.fadeCasesIntoView(this.visibleCases, this.otherCases);
    },
    onAllTabClick() {
      this.activeDept = "all";
      this.fadeCasesIntoView([], caseStudies);
    },
    onAnimTabClick() {
      this.activeDept = "animation";
      this.fadeCasesIntoView([], this.animCases);
    },
    onProdTabClick() {
      this.activeDept = "production";
      this.fadeCasesIntoView([], this.prodCases);
    },
    onIntTabClick() {
      this.activeDept = "interactive";
      this.fadeCasesIntoView([], this.intCases);
    },
    fadeCasesIntoView(currentlyVisible, casesToFadeIntoView) {
      this.visibleCases = currentlyVisible;
      casesToFadeIntoView === this.featuredCases
        ? (this.showViewMoreBtn = true)
        : (this.showViewMoreBtn = false);
      casesToFadeIntoView.forEach((c, index) => {
        setTimeout(() => this.visibleCases.push(c), index * 200);
      });
    },
  },
  metaInfo() {
    return {
      title: "A Sample of The 3 – Putting Ideas Into Motion",
      description:
        "See what The 3 can do for you. We’ve helped local and national brands alike reach their goals with our video production, technology, and content creation capabilities.",
    };
  },
};
</script>

<style lang="scss">
.work {
  .nav-link {
    padding: 5px 15px;
    margin: 5px;
    color: black;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
    }

    &.active {
      border: 1px solid black;
    }

    @media (max-width: 767px) {
      width: 38%;
      text-align: center;
    }
  }

  &__nav-line-break {
    width: 100%;
    height: 0 !important;
    margin: 0;
    padding: 0;
  }

  &__cases {
    display: grid;
    grid-gap: 19px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 100px;

    @include bp(tablet) {
      grid-template-columns: 50% 50%;
      grid-gap: 5px;
      margin-bottom: 150px;
    }
  }

  &__case {
    height: 50vw;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    animation: fadein 0.5s;

    @include bp(tablet-s) {
      height: 290px;
    }

    @include bp(tablet) {
      height: 194px;
    }

    @include bp(desk-l) {
      height: 261px;
    }

    @include bp(full) {
      height: 311px;
    }

    @include bp(xl) {
      height: 362px;
    }

    img {
      width: 100%;
      height: auto;
    }

    &__copy {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 106px;
      width: 100%;
      padding: 24px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000ad 101%);

      .h3 {
        color: white;
        margin-bottom: 0;
      }

      p {
        color: white;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  &__view-more {
    margin: 17px 0 89px 0;
    /* margin: 20px 0 150px 0; */

    .h3 {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $primaryFontColor;
      }
    }
  }
}
</style>
