import { createApp } from 'vue';
import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta';
import vueVimeoPlayer from 'vue-vimeo-player';
import App from './App.vue';
import router from './router';
import 'bootstrap'; // js. css imported in font-imports.scss

const vueMetaManager = createMetaManager();

const app = createApp( App )
  .use( router )
  .use( vueMetaManager )
  .use( vueMetaPlugin )
  .use( vueVimeoPlayer );

router.isReady()
  .then( () => app.mount( '#app' ) );
