<template>
  <div class="cmg">
    <div class="cmg__grid-container">
      <div class="cmg__grid-item row1col1" data-aos="fade-right">
        <case-media-grid-item :item="media.row1col1" />
      </div>
      <div class="cmg__grid-item row1col2" data-aos="fade-left">
        <case-media-grid-item :item="media.row1col2" />
      </div>
      <div class="cmg__grid-item row2col1" data-aos="fade-right">
        <case-media-grid-item :item="media.row2col1" />
      </div>
      <div class="cmg__grid-item row2col2" data-aos="fade-left">
        <case-media-grid-item :item="media.row2col2" />
      </div>

      <template v-if="rowThreeMedia">
        <div class="cmg__grid-item row3col1" data-aos="fade-right">
          <case-media-grid-item :item="media.row3col1" />
        </div>
      </template>
      <template v-if="rowThreeMedia">
        <div class="cmg__grid-item row3col2" data-aos="fade-left">
          <case-media-grid-item :item="media.row3col2" />
        </div>
      </template>
      <template v-if="rowFourMedia">
        <div
          :class="[
            'cmg__grid-item',
            'row4col1',
            { 'no-row-3': !rowThreeMedia },
          ]"
          data-aos="fade-right"
        >
          <case-media-grid-item :item="media.row4col1" />
        </div>
      </template>
      <template v-if="rowFourMedia">
        <div
          :class="[
            'cmg__grid-item',
            'row4col2',
            { 'no-row-3': !rowThreeMedia },
          ]"
          data-aos="fade-up"
        >
          <case-media-grid-item :item="media.row4col2" />
        </div>
      </template>
      <template v-if="rowFourMedia">
        <div
          :class="[
            'cmg__grid-item',
            'row4col3',
            { 'no-row-3': !rowThreeMedia },
          ]"
          data-aos="fade-left"
        >
          <case-media-grid-item :item="media.row4col3" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CaseMediaGridItem from "@/components/CaseMediaGridItem.vue";

export default {
  name: "CaseMediaGrid",
  components: { CaseMediaGridItem },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rowFourMedia() {
      return this.media.row4col1 && this.media.row4col2 && this.media.row4col3;
    },
    rowThreeMedia() {
      return this.media.row3col1 && this.media.row3col2;
    },
  },
};
</script>

<style lang="scss" scoped>
/* ================
      MOBILE
=================== */

.cmg {
  &__grid-container {
    display: grid;
    grid-gap: 4px;
    background-color: white;
    padding-bottom: 15px;
  }

  &__grid-item {
    overflow: hidden;
  }
}

/* ================
    TABLET AND UP
=================== */

@include bp(tablet) {
  .cmg {
    &__grid-container {
      grid-template-columns: repeat(11, 8.55%);
      grid-auto-rows: 53px;

      @include bp(desk-l) {
        grid-template-columns: repeat(11, 8.69%);
        grid-auto-rows: 71px;
      }

      @include bp(full) {
        grid-template-columns: repeat(11, 8.76%);
        grid-auto-rows: 84px;
      }

      @include bp(xl) {
        grid-auto-rows: 97px;
        grid-gap: 5px;
      }
    }

    &__grid-item {
      height: initial;
      min-height: 75px;

      &.row1col1 {
        grid-row: 1 / span 3;
        grid-column: 1 / span 8;
      }
      &.row1col2 {
        grid-row: 1 / span 3;
        grid-column: 9 / span 3;
      }
      &.row2col1 {
        grid-row: 4 / span 4;
        grid-column: 1 / span 4;
      }
      &.row2col2 {
        grid-row: 4 / span 4;
        grid-column: 5 / span 7;
      }
      &.row3col1 {
        grid-row: 8 / span 3;
        grid-column: 1 / span 6;
      }
      &.row3col2 {
        grid-row: 8 / span 3;
        grid-column: 7 / span 5;
      }
      &.row4col1 {
        grid-row: 11 / span 4;
        grid-column: 1 / span 4;

        &.no-row-3 {
          grid-row: 8 / span 4; // shift row up
        }
      }
      &.row4col2 {
        grid-row: 11 / span 4;
        grid-column: 5 / span 3;

        &.no-row-3 {
          grid-row: 8 / span 4; // shift row up
        }
      }
      &.row4col3 {
        grid-row: 11 / span 4;
        grid-column: 8 / span 4;

        &.no-row-3 {
          grid-row: 8 / span 4; // shift row up
        }
      }
    }
  }
}
</style>
