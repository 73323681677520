import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Interactive from '@/views/Interactive.vue';
import Production from '@/views/Production.vue';
import Work from '@/views/Work.vue';
import Animation from '@/views/Animation.vue';
import About from '@/views/About.vue';
import Contact from '@/views/Contact.vue';
import CaseStudy from '@/views/CaseStudy.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },

];

const router = createRouter( {
  history: createWebHistory( process.env.BASE_URL ),
  routes,
  scrollBehavior( to, from, savedPosition ) {
    const topOfPage = {
      top: 0,
      left: 0,
      behavior: 'auto',
    };
    return savedPosition || topOfPage;
  },
} );

router.afterEach( ( to, from ) => {
  if ( to.path.includes( '/study/' ) && from.path.includes( '/study/' )) {
    /* eslint-disable */
    to.meta.transitionName = 'no-fade';
     /* eslint-enable */
  }
} );

export default router;
