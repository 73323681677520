<template>
  <div class="depts">
    <div class="depts__view-all text-center">
      <span class="button" @click="onViewAllClick()">
        View All Of Our Work
      </span>
    </div>

    <div class="row">
      <div v-if="currentDept != 'animation'" class="col col-12 col-md-6 dept">
        <router-link to="/animation" class="dept__card" data-aos="fade-up">
          <div class="dept__card__img animation"></div>
          <div class="dept__card__content">
            <h3 class="h1">Animation</h3>
            <img
              class="dept-card__nav-arrow"
              src="@/assets/images/icon-nav-right.svg"
            />
          </div>
        </router-link>
      </div>
      <div v-if="currentDept != 'interactive'" class="col col-12 col-md-6 dept">
        <router-link to="/interactive" class="dept__card" data-aos="fade-up">
          <div class="dept__card__img interactive"></div>
          <div class="dept__card__content">
            <span class="h1 primary-link-color">Interactive</span>
            <img
              class="dept__card__content__nav-arrow"
              src="@/assets/images/icon-nav-right.svg"
              alt=""
              aria-hidden="true"
            />
          </div>
        </router-link>
      </div>
      <div v-if="currentDept != 'production'" class="col col-12 col-md-6 dept">
        <router-link to="/production" class="dept__card" data-aos="fade-up">
          <div class="dept__card__img production"></div>
          <div class="dept__card__content">
            <span class="h1 primary-link-color">Production</span>
            <img
              class="dept__card__content__nav-arrow"
              src="@/assets/images/icon-nav-right.svg"
              alt=""
              aria-hidden="true"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Departments",
  props: {
    currentDept: {
      validator(value) {
        return ["animation", "interactive", "production"].includes(value);
      },
    },
  },
  methods: {
    onViewAllClick() {
      this.$router.push({
        name: "Work",
        params: { dept: this.currentDept },
      });
    },
  },
};
</script>

<style lang="scss">
.depts {
  margin: 85px 0 75px 0;

  .h1.primary-link-color {
    color: $primaryLinkColor;
    font-weight: 500;
  }
}

.depts__view-all {
  margin-bottom: 110px;
}

.dept__card {
  position: relative;
  width: 100%;
  height: calc((100vw - 24px) * 0.59);
  max-height: 299px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 40px;
  cursor: pointer;

  @include bp(tablet) {
    height: calc((100vw - 24px) * 0.3);
  }

  &__img {
    position: relative;

    &.animation {
      @include bg-img-with-hover-effect("dept-img-animation.jpg");
    }

    &.interactive {
      @include bg-img-with-hover-effect("dept-img-interactive.jpeg");
    }

    &.production {
      @include bg-img-with-hover-effect("dept-img-production.jpg");
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      color: $primaryLightFontColor;
      display: inline;
      font-weight: 500;
    }

    &__nav-arrow {
      width: 49px;
      margin-left: 5px;
    }
  }
}
</style>
