<template>
  <div class="staff">
    <div class="staff__grid-container">
      <div
        class="staff__grid-item marisa"
        data-aos="fade-right"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Marisa Gartland</div>
          <p>Junior Producer</p>
        </div>
      </div>

      <div
        class="staff__grid-item jasmine"
        data-aos="fade-left"
        data-aos-delay="150"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Jasmine Kenya</div>
          <p>Animator</p>
        </div>
      </div>

      <div
        class="staff__grid-item clay"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Clay Brady</div>
          <p>Video Editor, Shooter</p>
        </div>
      </div>

      <div
        class="staff__grid-item jacob"
        data-aos="fade-right"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Jacob Campbell</div>
          <p>Associate Creative Director, Animation</p>
        </div>
      </div>

      <div
        class="staff__grid-item brandon"
        data-aos="fade-right"
        data-aos-delay="150"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Brandon Clements</div>
          <p>Immersive Experience Lead/Technologist</p>
        </div>
      </div>

      <div
        class="staff__grid-item anna"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">Anna Hardy</div>
          <p>Junior 3D Generalist</p>
        </div>
      </div>

      <div
        class="staff__grid-item george"
        data-aos="fade-left"
        data-aos-delay="450"
      >
        <div class="staff__grid-item__img"></div>
        <div class="staff__grid-item__copy">
          <div class="h3">George Maranville</div>
          <p>Sr. Producer</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StaffGrid",
  components: {},
  props: [],
  emits: [],
  methods: {},
};
</script>

<style lang="scss">
/* ================
      MOBILE
=================== */

.staff {
  &__grid-container {
    display: grid;
    grid-gap: 4px;
    background-color: transparent;
    padding-bottom: 138px;
    overflow: hidden;
  }

  &__grid-item {
    position: relative;
    background-color: black;
    text-align: left;
    font-size: 30px;
    color: white;
    height: 415px;
    overflow: hidden;

    @include bp(phone) {
      height: 480px;
    }

    @include bp(tablet-s) {
      height: 584px;
    }

    @include bp(tablet-m) {
      height: 565px;
    }

    &__copy {
      transition: transform 0.4s ease;
      position: absolute;
      bottom: 24px;
      left: 24px;

      .h3 {
        color: white;
        margin-bottom: 10px;
        line-height: 1.6rem;
      }

      p {
        color: white;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
      }

      @include bp(full) {
        transform: translateY(163%);
      }
    }

    &:hover &__copy {
      transform: translateY(0);
    }

    &.marisa &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-marisa-square.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0,
        $posX: 24%
      );

      @include bp(xl) {
        background-position-y: top;
      }
    }
    &.jasmine &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-jasmine.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0
      );

      @include bp(tablet) {
        background-position-x: 10%;
      }
    }
    &.clay &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-clay.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0
      );

      @include bp(phone) {
        background-position-y: 35%;
      }
    }
    &.jacob &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-jacob.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0,
        $posX: 24%,
        $posY: top
      );
    }
    &.brandon &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-brandon.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0
      );
    }
    &.anna &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-anna.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0
      );
    }
    &.george &__img {
      @include bg-img-with-hover-effect(
        $filename: "staff-george.jpg",
        $darken: false,
        $colorizeOnHover: false,
        $scaling: 1.0
      );
    }
  }
}

/* ================
    TABLET AND UP
=================== */

@include bp(tablet) {
  .staff {
    &__grid-container {
      grid-template-columns: auto auto auto;
      padding-bottom: 237px;
    }

    &__grid-item {
      height: initial;
      min-height: 386px;

      &__img {
        min-height: 386px;
      }

      &.marisa {
        grid-column: 1 / span 1;
        background-position-y: top;
      }
      &.clay {
        grid-row: 1 / span 2;
      }
      &.jacob {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
      }
      &.brandon {
        background-position-y: 26%;
      }
    }
  }
}
</style>
