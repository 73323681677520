<template>
  <div :class="['page-intro', { 'dark-theme': darkTheme }]">
    <div class="row justify-content-lg-center">
      <div
        class="col col-12 col-lg-4 page-intro__column"
        :data-aos="fadeIn ? 'fade-right' : null"
      >
        <h1 class="h1 page-intro__title">
          {{ title }}
        </h1>
      </div>
      <div
        class="col col-12 col-lg-6 page-intro__column"
        :data-aos="fadeIn ? 'fade-left' : null"
      >
        <h2 v-if="subtitle" class="h2 page-intro__subtitle">{{ subtitle }}</h2>
        <p class="page-intro__paragraph">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageIntro",
  components: {},
  props: {
    title: { type: String },
    subtitle: { type: String },
    description: { type: String },
    darkTheme: { type: Boolean, default: false },
    fadeIn: { type: Boolean, default: true },
  },
  emits: [],
  methods: {},
};
</script>

<style lang="scss">
.page-intro {
  padding-bottom: 79px;
  padding-top: 79px;
  text-align: center;
  overflow-x: hidden;

  @include bp(desk-l) {
    padding-bottom: 79px;
    padding-top: 100px;
    text-align: left;
  }
}

.h1.page-intro__title {
  padding-bottom: 9px;

  @include bp(tablet) {
    padding-bottom: 0;
  }
}

.h2.page-intro__subtitle {
  padding-bottom: 18px;
  font-weight: 500;

  @include bp(tablet) {
    padding-bottom: 0;
  }
}

.page-intro.dark-theme {
  .h1.page-intro__title,
  .h2.page-intro__subtitle,
  .page-intro__paragraph {
    color: white;
  }
}
</style>
