<template>
  <div class="production container">
    <page-intro title="Production" :description="description" />

    <vimeo-embed videoId="566189570">
      <video-caption
        primaryTitle="LG&E and KU"
        secondaryTitle="The Pet Station Country Club"
      />
    </vimeo-embed>
    <vimeo-embed videoId="566182989">
      <video-caption
        primaryTitle="GEA"
        secondaryTitle="Smart Window Air Conditioning Unit"
      />
    </vimeo-embed>
    <vimeo-embed videoId="566186418">
      <video-caption
        primaryTitle="LG&E and KU"
        secondaryTitle="LG&E and KU Manifesto"
      />
    </vimeo-embed>

    <departments currentDept="production" />
  </div>
</template>

<script>
import VimeoEmbed from "@/components/VimeoEmbed.vue";
import PageIntro from "@/components/PageIntro.vue";
import Departments from "../components/Departments.vue";
import VideoCaption from "../components/VideoCaption.vue";

export default {
  name: "Production",
  components: {
 VimeoEmbed, VideoCaption, Departments, PageIntro,
},
  data() {
    return {
      description:
        "Our production is not limited by formats. Live action video, still photography, and editorial are just the beginning. No project is too big or too small, we scale as needed. We always strive to maximize deliverables out of any shoot. Our 8,000 square foot space has a full sound studio with a 2-walled cyclorama, edit bays, audio suite, and a full commercial prep kitchen.",
    };
  },
  metaInfo() {
    return {
      title: "Production at The 3",
      description:
        "Our production is not limited by formats. Live action video, still photography, and editorial are just the beginning.",
    };
  },
};
</script>

<style lang="scss">
</style>
