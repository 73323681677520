<template>
  <div class="home">
    <div class="home__spotlight container">
      <h1 class="home__spotlight__headline">Under Construction.</h1>
      <p class="home__spotlight__text">
        The 3 is a full-service production studio located in downtown Louisville, KY, with a focus in live action production, post production, and 2D illustration and animation. Our website's currently undergoing some maintenance. In the meantime, for all inquiries, please reach out to our Executive Producer, Lisa Berg, at <a class="spotlight-text-link" href="mailto:lisa.berg@the3productions.com?subject=Agency Contact: The 3 Productions">lisa.berg@the3productions.com</a>.
      </p>

      <div class="construction-image-wrapper">
        <img src="../assets/images/trio-under-construction-v2.gif" alt="A gif that alternates between The 3 Logo in a black outline, and an animated, smiling, white glove wearing a hard hat." class="construction-image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: [],
  emits: [],
  methods: {},
  metaInfo() {
    return {
      title: "We Are The 3 - A Content Creation Studio",
      description:
        "Welcome to The 3. We are producers, animators, editors, creators, and renderers making content for every screen. Contact us to learn more.",
    };
  },
};
</script>

<style lang="scss">
.home {
  &__spotlight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.container {
      padding: 80px 24px;

      @include bp(desk) {
        padding: 120px 0;
      }
    }

    &__headline {
      font-size: 40px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      color: $primaryFontColor;
      margin-bottom: 24px;
    }

    &__text {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
      color: $secondaryFontColor;
      max-width: 890px;

      .spotlight-text-link {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 400;
        color: $primaryFontColor;
        text-decoration: underline;
      }
    }
  }

  .construction-image-wrapper {
    .construction-image {
      width: 100%;
      height: auto;
    }
  }
}
</style>
