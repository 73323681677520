<template>
  <div class="contact container">
    <div
      class="
        contact__headings
        d-flex
        flex-column
        justify-content-center
        align-items-center
        mt-5
        mb-4
        my-md-5
      "
    >
      <p class="mb-1">Contact Us</p>
      <h1 class="h1 text-center">Let's Get To Work.</h1>
      <p class="text-center">
        W'd love to help make your next project a success. Reach out today.
      </p>
    </div>

    <nav
      class="
        contact__nav
        d-flex
        flex-column flex-sm-row
        align-items-center
        justify-content-center
        my-3 my-md-3
      "
    >
      <span
        :class="['nav-link', { active: activeTab === 'business' }]"
        @click="activeTab = 'business'; formStatus = null;"
        >New Business</span
      >
      <span
        :class="['nav-link', { active: activeTab === 'general' }]"
        @click="activeTab = 'general'; formStatus = null;"
        >General Inquiries</span
      >
    </nav>

    <div class="contact__form">
      <form novalidate action="" class="needs-validation">
        <div class="form-control-wrapper">
          <input
            type="text"
            id="name"
            class="form-control"
            required
            placeholder="Name"
          />
          <div class="invalid-feedback">Please include your name.</div>
        </div>

        <div class="form-control-wrapper">
          <input
            type="email"
            id="email"
            class="form-control"
            required
            placeholder="Email"
          />
          <div class="invalid-feedback">Please include a valid email.</div>
        </div>

        <input type="tel" id="phone" class="form-control" placeholder="Phone" />

        <input
          type="text"
          id="company"
          :class="['form-control', { 'd-none': activeTab === 'general' }]"
          placeholder="Company"
        />

        <textarea
          id="comments"
          class="form-control"
          :placeholder="getCommentsPlaceholder()"
          rows="2"
        />

        <div
          v-if="formStatus === 'success'"
          class="alert alert-success mt-5"
          role="alert"
          data-aos="fade-up"
        >
          Your responses were successfully submitted. Thank you!
        </div>

        <div class="text-center mt-5 mb-3">
          <button type="submit" class="button">Let's Talk</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      activeTab: "business",
      formStatus: null,
    };
  },
  mounted() {
    this.handleFormSubmissions();
  },
  methods: {
    getCommentsPlaceholder() {
      return this.activeTab === "business"
        ? "Brief Project Details"
        : "Questions/Comments";
    },
    handleFormSubmissions() {
      const forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach((form) => {
        form.addEventListener(
          "submit",
          (event) => {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add("was-validated");

            if (form.checkValidity()) {
              this.formStatus = "success";
              form.classList.remove("was-validated");
              form.reset();
            } else {
               this.formStatus = "error";
            }
          },
          false,
        );
      });
    },
  },
};
</script>

<style lang="scss">
.contact {
  .nav-link {
    padding: 5px 15px;
    margin: 5px;
    color: $secondaryFontColor;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $primaryFontColor;
      text-decoration: none;
    }

    &.active {
      border: 1px solid black;
      color: $primaryFontColor;
    }
  }

  &__form {
    width: 100%;
    max-width: 568px;
    margin: auto;
    margin-bottom: 97px;

    .button {
      min-width: 143px;
    }
  }
}
</style>
